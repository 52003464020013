<script setup lang="ts">
import type { CmsContentElementModel } from '@/types/cms';
import type { JobModel } from '@/types/jobModel';

defineOptions({
  name: 'JobAudio',
});

const props = defineProps({
  job: {
    type: Object as PropType<JobModel>,
    required: true,
  },
  elements: {
    type: Array as PropType<CmsContentElementModel[]>,
    required: true,
  },
});

function getFiltersMatch({
  departmentId,
  countryId,
  careerLevelId,
}: {
  departmentId?: string;
  countryId?: string;
  careerLevelId?: string;
}) {
  return props.elements.find((element) => {
    const item = element.getData();
    const foundDepartmentGroup =
      (!departmentId && !item.departmentGroups.length) || item.departmentGroups.includes(departmentId);
    const foundCountry = (!countryId && !item.countryList.length) || item.countryList.includes(countryId);
    const foundCareerLevel = (!careerLevelId && !item.careerLevels.length) || item.careerLevels.includes(careerLevelId);
    return foundDepartmentGroup && foundCountry && foundCareerLevel;
  });
}

const audioModuleDataRef = computed(() => {
  const jobId = props.job.getId();

  // PRIO 1: job ids match
  let audioData = props.elements.find((element) => {
    const item = element.getData();
    return item.jobIds.some((idItem: any) => idItem.id.trim() === jobId);
  });

  if (!audioData) {
    // PRIO 2: filter combi matches
    const departmentId = props.job.getDepartmentId();
    const countryId = props.job.getCountryId();
    const careerLevelId = props.job.getCareerLevelId();

    audioData = getFiltersMatch({ departmentId, countryId, careerLevelId });

    if (!audioData) {
      audioData = getFiltersMatch({ departmentId, countryId });
    }

    if (!audioData) {
      audioData = getFiltersMatch({ departmentId, careerLevelId });
    }

    if (!audioData) {
      audioData = getFiltersMatch({ countryId, careerLevelId });
    }

    if (!audioData) {
      audioData = getFiltersMatch({ departmentId });
    }

    if (!audioData) {
      audioData = getFiltersMatch({ countryId });
    }

    if (!audioData) {
      audioData = getFiltersMatch({ careerLevelId });
    }
  }
  return audioData?.getData();
});
</script>

<template>
  <div v-if="audioModuleDataRef" class="job-post__audio">
    <MopCmsCompanyAudio :data="audioModuleDataRef" />
  </div>
</template>

<style lang="scss" scoped>
.job-post__audio {
  margin-top: 60px;
  margin-left: -$global-padding;
  margin-right: -$global-padding;
}
</style>
