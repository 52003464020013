<script setup lang="ts">
import type { Benefit } from '@mop/ui/components/cms/UiCmsBenefit';
import type { CmsContentElementModel } from '@/types/cms';
import type { JobModel } from '@/types/jobModel';

defineOptions({
  name: 'JobBenefits',
});

const props = defineProps({
  job: {
    type: Object as PropType<JobModel>,
    required: true,
  },
  elements: {
    type: Array as PropType<CmsContentElementModel[]>,
    required: true,
  },
});

const benefitsRef = computed(() => {
  const cmsNotReady = !props.elements;
  if (cmsNotReady) {
    return {};
  }
  let benefits: Benefit;
  let defaultBenefits: any;
  const departmentId = props.job.getDepartmentId();
  const countryId = props.job.getCountryId();
  props.elements.forEach((element) => {
    const item = element.getData();
    if (!benefits && item.departmentGroup.includes(departmentId)) {
      benefits = item;
    }
    if (!defaultBenefits && item.departmentGroup.includes('default')) {
      defaultBenefits = item;
    }
  });
  benefits ??= defaultBenefits;
  if (!benefits) {
    return {};
  }
  benefits.body = benefits.body.filter(
    (item: any) => !item.countryList || item.countryList.length === 0 || item.countryList.includes(countryId),
  );
  return benefits;
});
</script>

<template>
  <div class="jobs-benefits-wrapper">
    <UiCmsBenefit :data="benefitsRef" />
  </div>
</template>
